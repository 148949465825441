import React, { Component } from 'react';
import { Notifier } from '@airbrake/browser';

import { getToken } from "./utils";

class AirbrakeErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = new Notifier({
      projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
      projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY,
      environment: process.env.NODE_ENV
    });
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.airbrake.notify({
      error: error,
      params: { info: info }
    });
  }

  render() {
    if (this.state.hasError) {
      // Redirect on error
      window.location.replace(
        `${process.env.REACT_APP_STUDIES_API_DOMAIN}/survey-match/redirect?token=${getToken()}`
      );
    }
    return this.props.children;
  }
}

export default AirbrakeErrorBoundary;