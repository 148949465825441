import 'url-search-params-polyfill'

export const getToken = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("token");
};

export const getPrescreenerMessage = (data) => {
  try {
    const message = JSON.parse(data);
    if (message?.type === "prescreener") {
      return message;
    }
  } catch (error) {
    return null;
  }
};

export const addProfileParameters = (url) => {
  if (!url) return;
  try{
    const gatewayUrl = new URLSearchParams(window.location.search);
    let params = url.includes("?") ? "&" : "?";
    params += 'gateway=true';
    if (gatewayUrl.has("encProfile") && gatewayUrl.has("profileiv")) {
      params += `&encProfile=${gatewayUrl.get(
        "encProfile"
      )}&profileiv=${gatewayUrl.get("profileiv")}`;
    }
    url += params;

  }catch(err){
    console.log(err.message)
  }

  return url;
};
