import "./App.scss";
import React, { useState, useEffect, useRef } from "react";
import LoadingAnimation from "./components/LoadingAnimation";
import { addProfileParameters, getPrescreenerMessage, getToken } from "./utils";

function App() {
  const [userData, setUserData] = useState({});
  const [prescreenerComplete, setPrescreenerComplete] = useState(false);
  const prescreenerStatusRef = useRef("");
  const [error, setError] = useState("");

  useEffect(() => {
    const messageListener = (event) => {
      const message = getPrescreenerMessage(event.data);
      if (
        message &&
        (event.origin === process.env.REACT_APP_PRESCREENER_ORIGIN ||
          event.origin === process.env.REACT_APP_QMEE_PRESCREENER_ORIGIN)
      ) {
        prescreenerStatusRef.current = message.status;
        if (message.status === "complete") {
          setPrescreenerComplete(true);
        }
        if (
          (message.status === "survey finished" ||
            message.status === "complete") &&
          userData.hasSurveys && userData.hasHighScoringSurvey
        ) {
          const token = getToken();
          window.location.replace(
            `${process.env.REACT_APP_STUDIES_API_DOMAIN}/survey-match/redirect?token=${token}`
          );
          setPrescreenerComplete(true);
        }
      }
    };
    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [userData]);

  useEffect(() => {
    let timeout;
    const token = getToken();
    if (
      (token && !userData.prescreenerUrl) ||
      (userData.prescreenerUrl && prescreenerComplete)
    ) {
      timeout = setTimeout(() => {
        window.location.replace(
          `${process.env.REACT_APP_STUDIES_API_DOMAIN}/survey-match/redirect?token=${token}`
        );
      }, process.env.REACT_APP_TIMEOUT || 5000);
    }

    if (userData.hasSurveys && userData.hasHighScoringSurvey && prescreenerComplete) {
      window.location.replace(
        `${process.env.REACT_APP_STUDIES_API_DOMAIN}/survey-match/redirect?token=${token}`
      );
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [userData, prescreenerComplete]);

  useEffect(() => {
    const token = getToken();
    let resetDelay;
    let webSocket;

    if (!token) {
      setError("Please provide a user token");
      return;
    }


    const resetWebsocket = () => {
      clearTimeout(resetDelay)
      resetDelay = setTimeout(() => {
        startWebSocket()
      }, 1000);
    }

    const startWebSocket = () => {
      webSocket = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}?token=${token}`
      );

      webSocket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (
          ((message.hasSurveys) &&
            prescreenerStatusRef.current !== "survey started") ||
          message.paused
        ) {
          window.location.replace(
            `${process.env.REACT_APP_STUDIES_API_DOMAIN}/survey-match/redirect?token=${token}`
          );
        } else {
          setUserData((prevUserData) => ({
            ...message,
            prescreenerUrl: prevUserData?.prescreenerUrl
              ? prevUserData.prescreenerUrl
              : addProfileParameters(message.prescreenerUrl),
          }));
        }
      };

      webSocket.onerror = ('error', (event) => {
        console.error("WebSocket error occurred:", event);
      });

      webSocket.addEventListener('close', resetWebsocket);
    };

    startWebSocket();

    return () => {
      clearTimeout(resetDelay)
      if (webSocket) {
        webSocket.removeEventListener('close', resetWebsocket);
        webSocket.close();
      }
    };
  }, []);

  return (
    <div className="container">
      {userData.prescreenerUrl && !prescreenerComplete ? (
        <iframe
          className="iframe"
          style={{ border: "0" }}
          title="Signup survey"
          src={userData.prescreenerUrl}
        />
      ) : error ? (
        <h1>Error: {error}</h1>
      ) : (
        <>
          {prescreenerComplete && <h1>Finding the best survey for you</h1>}
          <LoadingAnimation />
        </>
      )}
    </div>
  );
}

export default App;
